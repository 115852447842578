@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
/* 
#media .swiper-slide {
    width: 250px !important
} */

@import "~@flaticon/flaticon-uicons/css/all/all";